import React from "react"
import Navbar from "../shared/Navbar/Navbar"
import Footer from "../shared/Footer/Footer"
import "../global.css"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  return (
    <main>
      <Navbar/>
      {children}
      <CookieConsent style={{ background: "black" }}
                     enableDeclineButton
                     location="top"
                     buttonText="Accept"
                     buttonStyle={{ background: "#90CAF9" }}
                     declineButtonText="Decline"
                     cookieName="gatsby-gdpr-google-analytics"
      >
        <p>This site uses cookies. These cookies are used to collect information about how you interact with this
          website.
          I use this information in order to improve and customize your browsing experience and for analytics and
          metrics
          about the visitors on this website.</p>
        <p>If you decline, your information won’t be tracked when you visit this website. A single cookie will be used
          in
          your browser to remember your preference not to be tracked.</p>
      </CookieConsent>
      <Footer/>
    </main>
  )
}

export default Layout
