import React from "react"
import styles from "./footer.module.css"
import links from "../../../constants/links"
import socialLinks from "../../../constants/social-links"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {links.map((item, index) => {
          return (
            <a href={item.path} key={index}>
              {item.text}
            </a>
          )
        })}
        <AniLink fade to="/portfolio">
          Portfolio
        </AniLink>
        <AniLink fade to="/blog">
          Blog
        </AniLink>
      </div>
      <div className={styles.icons}>
        {socialLinks.map((item, index) => {
          return (
            <a key={index} href={item.url} target='_blank' rel="noopener noreferrer">
              {item.icon}
            </a>
          )
        })}
      </div>

      <div className={styles.copyright}>
        &copy; Nolan James {new Date().getFullYear()}
      </div>

      <div className={styles.copyright}>
        <AniLink fade to="/privacy" className={styles.link}>
          Privacy Policy
        </AniLink>
      </div>
    </footer>
  )

}

export default Footer
