import React from "react"
import { FaFacebook, FaLinkedin } from "react-icons/fa"

export default [
  {
    icon: <FaFacebook/>,
    url: "https://www.facebook.com/NolanJamesDesign",
  },
  {
    icon: <FaLinkedin/>,
    url: "https://www.linkedin.com/company/nolan-james-nj",
  },
]
