import React from "react"
import { Helmet } from "react-helmet/es/Helmet"
import { graphql, useStaticQuery } from "gatsby"

const getSEOData = graphql`
    query {
        site {
            siteMetadata {
                siteTitle: title
                siteDescription: description
                author
                siteUrl
                image
                twitterUsername
            }
        }
    }

`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(getSEOData)
  const { siteTitle, siteDescription, twitterUsername, image, siteUrl, author } = site.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`}>
      <meta name='description' content={description || siteDescription}/>
      <meta name='image' content={image}/>
      <meta name='author' content={author}/>

      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:creator' content={twitterUsername}/>
      <meta name='twitter:title' content={title}/>
      <meta name='twitter:description' content={description}/>
      <meta name='twitter:image' content={`${siteUrl}${image}`}/>

      <meta property='og:url' content={siteUrl}/>
      <meta property='og:type' content='website'/>
      <meta property='og:title' content={siteTitle}/>
      <meta property='og:description' content={description}/>
      <meta property='og:image' content={`${siteUrl}${image}`}/>
      <meta property='og:image:width' content='400'/>
      <meta property='og:image:height' content='300'/>

    </Helmet>
  )
}

export default SEO
