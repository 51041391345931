export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/#about",
    text: "about",
  },
  {
    path: "/#services",
    text: "services",
  },
  {
    path: "/#contact",
    text: "contact",
  },
]
