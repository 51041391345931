import React, { useEffect, useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"
import styles from "./navbar.module.css"
import links from "../../../constants/links"
import socialLinks from "../../../constants/social-links"
import logo from "../../../images/logo.png"
import { MdFlare } from "react-icons/md"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  let [isDarkNav, setIsDarkNav] = useState(false)

  const toggleNav = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsDarkNav(window.location.href.indexOf("blog") > 0 ||
      window.location.href.indexOf("portfolio") > 0 || window.location.href.indexOf("projects") > 0 ||
      window.location.href.indexOf("dino-match") > 0 || window.location.href.indexOf("privacy") > 0 )
  }, [])

  return (
    <nav
      className={isDarkNav
        ? `${styles.navbar2}`
        : `${styles.navbar}`}
      color='transparent'>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <img src={logo} alt="nolan james logo"/>
          <button type='button' className={styles.logoBtn} onClick={toggleNav}>
            <MdFlare className={styles.logoIcon}/>
          </button>
        </div>
        <ul className={isOpen ? `${styles.navLinks} ${styles.showNav}` : `${styles.navLinks}`}>
          {links.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.path}>
                  {item.text}
                </Link>
              </li>
            )
          })}
          <AniLink fade to="/portfolio">
            Portfolio
          </AniLink>
          <AniLink fade to="/blog">
            Blog
          </AniLink>
        </ul>
        <div className={styles.navSocialLinks}>
          {socialLinks.map((item, index) => {
            return (
              <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                {item.icon}
              </a>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
